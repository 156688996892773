import { render, staticRenderFns } from "./Read.vue?vue&type=template&id=317b6b15&lang=pug&"
import script from "./Read.vue?vue&type=script&lang=ts&"
export * from "./Read.vue?vue&type=script&lang=ts&"
import style0 from "./Read.vue?vue&type=style&index=0&id=317b6b15&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports